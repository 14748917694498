<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <!-- <a-form-item>
        <a-input v-model="form.name" placeholder="名称"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.id" placeholder="编号"/>
      </a-form-item> -->
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <!-- <a-button @click="resetForm" style="margin-left:20px;">重置</a-button> -->
      </a-form-item>
    </a-form>

    <a-table
      :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      bordered
    >
      <span slot="action1" slot-scope="scope">
        <div>{{scope.id}}</div>
        <div style="color:#1890ff;">{{ scope.status | formatAudit }}</div>
      </span>
      <span slot="action2" slot-scope="scope">
        <div>{{scope.realName}}</div>
        <div>{{scope.idCard}}</div>
      </span>
      <span slot="action3" slot-scope="scope">
        <div v-if="scope.cardList != null">
          <div>{{scope.cardList[0].bankCardName}}</div>
          <div>{{scope.cardList[0].bankBranchName}}</div>
          <div>{{scope.cardList[0].bankCardNo}}</div>
        </div>
      </span>
      <span slot="action4" slot-scope="scope">
        <div>创建时间:{{scope.gmtCreate}}</div>
        <div v-if="scope.gmtAudit != null">审核时间:{{scope.gmtAudit}}</div>
      </span>
    </a-table>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: this.$route.params.breadName,
          url: '/account/' + this.$route.params.accountType + '/list'
        },
        {
          name: '大使管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      form: {
        inviteId: this.$route.params.userId,
        inviteScene: 'communeSaleJoin'
      },
      account: {},
      columns: [
        { title: '编号', key: 'id', scopedSlots: { customRender: 'action1' } },
        { title: '大使信息', key: '', scopedSlots: { customRender: 'action2' } },
        { title: '银行账户信息', key: '', scopedSlots: { customRender: 'action3' } },
        { title: '时间', key: 'gmtCreate', scopedSlots: { customRender: 'action4' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.account = {
      userId: this.$route.params.userId,
      accountType: this.$route.params.accountType,
      breadName: this.$route.params.breadName
    }
    this.reloadList()
  },
  filters: {
    formatAudit (v) {
      if (v === 'new') {
        return '新建'
      }
      if (v === 'waitAudit') {
        return '待审核'
      }
      if (v === 'normal') {
        return '正常'
      }
      if (v === 'pause') {
        return '停用'
      }
    }
  },
  methods: {
    // 重置表单
    // resetForm (formName) {
    //   this.form.id = ''
    //   this.form.name = ''
    //   this.queryList()
    // },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'communeSaleList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
